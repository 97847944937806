export enum FeatureFlagEnum {
  PurchaseOrderRevamp = 'purchase-order-revamp',
  DraftOrderInvoice = 'draft-order-invoice',
  MultiTemplateEnabled = 'multi-template-enabled',
  MultiTemplateLogReceipts = 'multi-template-log-receipts-on-plugin',
  WebSocketPingPongEnabled = 'web-socket-ping-pong-enabled',
  InventoryAPIV3 = 'inventory-api-v3',
  CustomFields = 'custom-fields',
  CustomFieldsPackages = 'custom-field-packages',
  PurchaseOrderRevampAdditionalCost = 'purchase-order-revamp',
  PurchaseOrderRevampDiscount = 'purchase-order-revamp',
  PurchaseOrderRevampDeliveryDate = 'purchase-order-revamp',
  ForceOffline = 'force-offline',
  PurchaseOrderRevampImportProduct = 'purchase-order-revamp',
  PurchaseOrderAddSimpleProduct = 'purchase-order-revamp',
  RegisterReport = 'register-report',
  StockCountImportProducts = 'stock-count-import-products',
  PublishProducts = 'publish-products',
  ZatcaEnabled = 'zatca-enabled',
  ZatcaOffline = 'zatca-offline',
  ReferralProgram = 'referral-program',
  OfflineInventorySyncInterval = 'offline-inventory-sync-interval',
  SoftPosEnabled = 'softpos-enabled',
  SubscriptionPlanCustomAttributes = 'subscription-plans-custom-attributes',
  OfflineFirstEnabled = 'offline-first-enabled',
  CustomFieldsMetabaseReports = 'custom-fields-metabase-reports',
  posUiWebUIChanges = 'pos-ui-web-ui-changes',
  OfflineFirst = 'offline-first',
  SubscriptionsV2 = 'subscriptions-v2',
  PromotionRevamp = 'promotion-revamp-enabled',
  Marketing = 'marketing',
  PosUiWebUIChanges = 'pos-ui-web-ui-changes',
  RecurringExpense = 'recurring-expense',
  AccountingOpeningBalance = 'accounting-opening-balance',
  SupplierDeleteDeactivate = 'supplier-delete-deactivate',
  StcManagerCustomerId = 'stc-manager',
  DashboardDateRange = 'dashboard-date-range',
  CoBrowsingEnabled = 'enable-co-browsing',
  PosReborn = 'pos-reborn',
  PurchaseOrderRevampV2 = 'purchase-order-revamp-v2',
  PurchaseOrderProtobuf = 'purchase-order-protobuf',
  IssueDatePurchaseOrderRevampV2 = 'issue-date-purchase-order-revamp-v2',
  StockCountRevamp = 'stock-count-revamp-v2',
  StockControlRevamp = 'stock-control-revamp',
  TransferStockV2 = 'transfer-stock-control-service',
  IntegrationsV2 = 'integrations-v2',
  IntegrationsV2Orders = 'integrations-v2-orders',
  ProductListingV4 = 'product-listing-v4',
  SmsOtp = 'sms-otp',
  CashManagementV2 = 'cash-management-v2',
  StockControlReportCubeJs = 'stock-control-cubejs',
  TaxesReportCubeJs = 'taxes-report-cubejs',
  DatatableV3ProductsList = 'datatable-v3-products-list',
  InventoryReportCubeJs = 'inventory-report-cubejs',
  B2BNewFlow = 'B2BNewFlow',
  AccountingActivityService = 'accounting-activity-service',
  RewaaOnboarding = 'rewaa-onboarding',
  CustomFieldChargeBeeEnabled = 'custom-fields-chargebee',
  PermissionsUserCreationV2 = 'permissions-v2-users',
  OfflineInvoiceSyncRevamp = 'offline-invoice-sync-revamp',
  POSDiscountUxV2 = 'pos-discount-ux-v2',
  PosReceiptCurrency = 'pos-receipt-currency',
  DoomsDaySubscriptionV2 = 'doomsday-subscriptions-v2',
  CustomerReceiveDebitDesignRevamp = 'customer-receive-debit-design-revamp',
  ReportsV2 = 'reports-v2',
  OfflineInvoicesCountPollingInterval = 'offline-invoices-count-polling-interval',
  RewaaPayEnabled = 'rewaa-pay-enabled',
  MinimalistInvoiceDesign = 'minimalist-invoice-design',
  StcMangerInvoicing = 'stc-manager-invoice',
  CustomLoader = 'custom-loader',
  OpeningBalanceSetup = 'opening-balance-setup',
  LoadingSkeleton = 'loading-skeleton',
  RestrictFeatureUsage = 'restrict-feature-usage',
  OcrService = 'ocr-service',
  NewPurchaseOrderInAmountCollected = 'new-purchase-order-in-amount-collected',
  ProductMovementReportV2 = 'product-movement-report-v2',
  TobaccoTaxEnabled = 'tobacco-tax-enabled',
  WarehouseCubeReports = 'warehouse-cube-reports',
  EnigmaCubeReports = 'enigma-cube-reports',
  SalesCubeReports = 'sales-report-cubejs',
  SearchByCustomer = 'search-by-customer',
  ZatcaQRCodeEnabled = 'zatca-qr-code-enabled',
  JeSearchHighLights = 'je-search-highlights',
  ShowTaxDeclarationOtherTax = 'show-tax-declaration-other-tax',
  NucleusPermissions = 'nucleus-permissions',
  AdditionalCostTax = 'additional-cost-tax',
  PaidAppFix = 'paid-app-fix',
  NewSideNavMenuComp = 'new-side-nav-menu-composite',
  ImportProductsBeta = 'import-products-beta',
  ImportSuppliers = 'import-suppliers',
  TaxDeclarationSalesEnigma = 'tax-declaration-sales-enigma',
  AccountTransactionsHtmlExport = 'account-transactions-html-export',
  SupplierOpeningBalance = 'supplier-opening-balance',
  ReceiveDebitRevamp = 'receive-debit-revamp',
  AccountingIncomeStatementLocation = 'acc-income-statement-location',
  IndepDashboard = 'indep-dashboard',
  HRModule = 'enable-hr-module',
  AccountingAccountTransactionsSortOrder = 'account-transactions-sort-order',
  HrModuleSubscription = 'hr-module-subscription-feature',
  PosUnstableInternetFallback = 'pos-unstable-internet-fallback',
  BillingInfoZatcaStatus = 'enable-zatca-status-on-billing-info',
  ZatcaConnectFormV2 = 'zatca-connect-form-v2',
  ReportsV3 = 'reports-v3',
  CustomerAccountStatementV2 = 'customer-account-statement-v2',
  EnableSubscribedUserOnboarding = 'enable-subscribed-user-onboarding',
  EnableFreeTrialUserOnboarding = 'enable-free-trial-user-onboarding',
  JeSearchRefId = 'je-search-reference-id',
  OnlineOrderErrorBanner = 'online-order-error-banner',
  WooCommerceInstallationBlock = 'woo-commerce-installation-block',
  AccTransOpeningClosingBalance = 'account-transaction-opening-closing-balance',
  FinanceReportsImprovements = 'finance-reports-improvements',
  DetailViewForArApHeads = 'detail-view-for-ar-ap-heads',
  InvoiceAndCreditNotesListing = 'invoice-and-credit-notes-listing',
  InventoryService = 'inventory-service',
  TransferStockAllowNullCP = 'transfer-stock-allow-null-cp',
  AccountingSetupGuide = 'accounting-setup-guide',
  OfflineCustomersChangeSetV2 = 'offline-customer-change-set-v2',
  CustomerOpeningBalance = 'customer-opening-balance',
  CalculationWithBigJs = 'calculation-with-bg-js',
  FreeTrialOnboardingProducts = 'free-trial-onboarding-products',
  OrdersWithErrorsInvoiceIssuanceFlow = 'orders-with-errors-invoice-issuance-flow',
  OpeningBalanceImport = 'opening-balance-import',
}
