import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, catchError, map, of } from 'rxjs';
import {
  CustomFieldMetadata,
  ICustomFieldServerResponseDetail,
  IModules,
} from '../../users-settings/custom-field.types';
import { environment } from '../../../environments/environment';
import { BYPASS_NORMAL } from '../interceptors/http-error.interceptor';

@Injectable()
export class CustomFieldInputService {
  customFieldUrl = environment.customFieldApiPath;

  private customFields$: BehaviorSubject<ICustomFieldServerResponseDetail[]> =
    new BehaviorSubject([]);

  constructor(private http: HttpClient) {}

  getCustomFields(
    currentURL: string,
    bypassTenant = false,
  ): Observable<ICustomFieldServerResponseDetail[]> {
    const modules = this.getModulesFromUrl(currentURL);
    const filters = [
      {
        columnName: 'module',
        value: modules,
        matchMode: 'contains',
      },
    ];

    const params = new HttpParams()
      .set('sortBy', 'created_at')
      .set('sortDirection', 'ASC')
      .set('limit', 100)
      .set('offset', 0)
      .set('filters', JSON.stringify(filters))
      .set('bypassTenant', bypassTenant);
    return this.http
      .get<
        [ICustomFieldServerResponseDetail[], [total: number]]
      >(`${this.customFieldUrl}/custom-field/list`, { params, context: new HttpContext().set(BYPASS_NORMAL, true) })
      .pipe(
        catchError(() => of([[]])),
        map(([customFields]) => this.setCustomFields(customFields)),
      );
  }

  getCustomFieldsForReports(
    dashboardName: string,
  ): Observable<ICustomFieldServerResponseDetail[]> {
    const params = new HttpParams().set('dashboardName', dashboardName);
    return this.http
      .get<
        [ICustomFieldServerResponseDetail[]]
      >(`${this.customFieldUrl}/custom-field/list/report`, { params, context: new HttpContext().set(BYPASS_NORMAL, true) })
      .pipe(
        catchError(() => of([[]])),
        map(([customFields]) => this.setCustomFields(customFields)),
      );
  }

  setCustomFields(
    customFields: ICustomFieldServerResponseDetail[],
  ): ICustomFieldServerResponseDetail[] {
    this.customFields$.next(customFields);
    return this.customFields$.getValue();
  }

  addCustomField(customField: ICustomFieldServerResponseDetail): void {
    this.customFields$.getValue().push(customField);
    this.customFields$.next(this.customFields$.getValue());
  }

  getModulesFromUrl(Url: string): IModules[] | '' {
    const moduleToRouteMap: Record<string, IModules[]> = {
      '/inventory': ['inventory'],
      '/customers': ['customer'],
      '/pos': ['sales', 'inventory'],
      '/reports': ['reports'],
    };

    for (const key of Object.keys(moduleToRouteMap)) {
      if (Url.includes(key)) {
        return moduleToRouteMap[key];
      }
    }
    return '';
  }

  getCustomFieldsObs(): Observable<ICustomFieldServerResponseDetail[]> {
    return this.customFields$.asObservable();
  }

  search(
    customFieldId: number,
    cfMetadata: CustomFieldMetadata,
    params: {
      [x: string]: string | number;
    },
  ) {
    const { autoCompleteUrl, response } = cfMetadata;
    const [endpoint, queryParamsStrings] = autoCompleteUrl.split('?');
    const queryParams = queryParamsStrings.split('&');
    const SUBSTR_IN_CURLY_BRACES_REGEX = /{{(.*?)}}/g;

    let queryString = '';
    queryParams.forEach((queryParam) => {
      const [valueToReplace] = queryParam
        .match(SUBSTR_IN_CURLY_BRACES_REGEX)
        .map((s) => s.replace('{{', '').replace('}}', ''));

      if (params[valueToReplace]) {
        if (queryString.length) {
          queryString += '&';
        }
        queryString += `${queryParam.replace(
          `{{${valueToReplace}}}`,
          `${params[valueToReplace]}`,
        )}`;
      }
    });
    if (!queryString) {
      throw new Error('Invalid Query Params');
    }
    return this.http
      .get<{ data: any[] }>(`/api${endpoint}?${queryString}`)
      .pipe(
        map(({ data }) => ({
          customFieldId,
          data: data.map((value) => ({
            displayText: value[response.displayText],
            value: value[response.value],
          })),
        })),
      );
  }
}
