import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';
import { PublishResponse } from 'src/app/shared/model/PublishResponse';
import { VariantToTrack } from 'src/app/shared/model/VariantToTrack';
import { IProductSerachFilter } from 'src/app/shared/model/product-search-and-filter';
import { FeatureFlagEnum } from '../../../shared/constants/feature-flag.constants';
import { UploadFile } from '../../../shared/model/UploadFile';
import { QueryByPage } from '../../../shared/services/pagination-interface';
import { FeatureFlagService } from '../../../shared/services/types/feature-flag.service.interface';
import { GetProductsResponse, Product } from '../../model/product';
import { ProductVariant } from '../../model/product-variant';
import { DeleteResponse } from '../../variants/services/delete-response';
import {
  GetVariantByIdForPOSParams,
  GetVariantByIdForPOSResponse,
} from './types/product.service.types';
import { BYPASS_NORMAL } from '../../../shared/interceptors/http-error.interceptor';
import { RewaaBaseApiResponse } from '@rewaa-team/types';
import { PendingProductResponse } from '../../../orders-v2/data/models/pending-products.model';
import {
  mapProductV1toV2,
  mapProductV2toV1,
  mapProductsToInvService,
  mapTracksV1ToV2,
} from '../mappers/product.mapper';
import { ProductV2 } from '../mappers/product-mapper.types';
import { SupplierService } from '../../../shared/services/supplier.service';

const API_URL = '/api';

const featureFlagName = FeatureFlagEnum.InventoryService;
const baseInvUrl = '/inventory-service';

@Injectable()
export class ProductService implements QueryByPage<Product> {
  private productListV4IsEnabled = false;

  constructor(
    private http: HttpClient,
    private featureFlagService: FeatureFlagService,
    private supplierService: SupplierService,
  ) {}

  checkProductListV4Validity(): Observable<boolean> {
    return this.featureFlagService.isEnabled(FeatureFlagEnum.CustomFields).pipe(
      switchMap((customFieldsValue: boolean) => {
        if (customFieldsValue) {
          return of(customFieldsValue);
        }
        return this.featureFlagService.isEnabled(
          FeatureFlagEnum.ProductListingV4,
        );
      }),
    );
  }

  checkInventoryFlag(): Observable<boolean> {
    return this.featureFlagService.isEnabled(FeatureFlagEnum.InventoryService);
  }

  getAnyPageByQuery(query: IProductSerachFilter): Observable<any> {
    const productQueryParams = new HttpParams()
      .set(
        'fromDate',
        query.dates && query.dates.fromDate
          ? query.dates.fromDate.toString()
          : '',
      )
      .set(
        'toDate',
        query.dates && query.dates.toDate ? query.dates.toDate.toString() : '',
      )
      .set('search', query.search || '')
      .set('scannerCode', query.scannerCode || '')
      .set('channel', query.channel || '')
      .set('type', query.type || '')
      .set('offset', query.offset || '0')
      .set('limit', query.limit || '10')
      .set('sortBy', query.sortBy || '')
      // .set('getVariants', query.getVariants || 'true')
      .set('sortDirection', query.sortDirection?.toString() || '')
      .set('filters', query.filters?.toString() || '');
    return this.checkInventoryFlag().pipe(
      switchMap((value) => {
        if (!value) {
          return this.checkProductListV4Validity().pipe(
            switchMap((flagValue) => {
              const version = flagValue ? 'v4' : 'v3';
              const url = `${API_URL}/products-${version}`;
              return this.http.get<{ rows: Product[]; count: number }>(url, {
                params: productQueryParams,
                context: new HttpContext().set(BYPASS_NORMAL, true),
              });
            }),
          );
        }
        return combineLatest([
          this.supplierService.getSuppliers(),
          this.http.get<{
            data: GetProductsResponse[];
            meta: { total: number; limit: number; offset: number };
          }>(`${API_URL}${baseInvUrl}/variants`, {
            params: productQueryParams,
            context: new HttpContext().set(BYPASS_NORMAL, true),
          }),
        ]).pipe(
          map(([suppliers, response]) =>
            mapProductsToInvService(response.data, response.meta, suppliers),
          ),
        );
      }),
    );
  }

  getPaginatedProductsV3(
    query: IProductSerachFilter,
  ): Observable<{ result: Product[]; total: number }> {
    const productQueryParams = new HttpParams()
      .set(
        'fromDate',
        query.dates && query.dates.fromDate
          ? query.dates.fromDate.toString()
          : '',
      )
      .set(
        'toDate',
        query.dates && query.dates.toDate ? query.dates.toDate.toString() : '',
      )
      .set('search', query.search || '')
      .set('scannerCode', query.scannerCode || '')
      .set('channel', query.channel || '')
      .set('type', query.type || '')
      .set('offset', query.offset || '0')
      .set('limit', query.limit || '10')
      .set('sortBy', query.sortBy || '')
      .set('sortDirection', query.sortDirection?.toString() || '')
      .set('filters', query.filters?.toString() || '')
      .set('columns', query.columns?.toString() || '');
    const url = `${API_URL}/products-v3`;
    return this.http.get<{ result: Product[]; total: number }>(url, {
      params: productQueryParams,
      context: new HttpContext().set(BYPASS_NORMAL, true),
    });
  }

  saveProduct(product, context?): Observable<any> {
    return this.featureFlagService.isEnabled(featureFlagName).pipe(
      switchMap((value) => {
        if (!value) {
          return this.http.post<Product>(`${API_URL}/products`, product, {
            context,
          });
        }
        const productV2 = mapProductV1toV2(product);
        return this.http
          .post<{ data: ProductV2 }>(
            `${API_URL}${baseInvUrl}/products`,
            productV2,
            {
              context,
            },
          )
          .pipe(
            switchMap((response) =>
              this.supplierService
                .getSuppliers()
                .pipe(
                  map((suppliers) =>
                    mapProductV2toV1(response.data, suppliers),
                  ),
                ),
            ),
          );
      }),
      first(),
    );
  }

  updateProduct(product: Product): Observable<Product> {
    return this.http.put<Product>(`${API_URL}/products/${product.id}`, product);
  }

  saveProductImages(productId, files): Observable<any> {
    return this.http.post<UploadFile[]>(
      `${API_URL}/products/${productId}/images`,
      files,
    );
  }

  deleteProducts(listOfIds: Array<number>): Observable<DeleteResponse> {
    const ids = listOfIds.join(',');
    return this.featureFlagService.isEnabled(featureFlagName).pipe(
      switchMap((value) => {
        if (!value) {
          return this.http.delete<DeleteResponse>(
            `${API_URL}/products?ids=${ids}`,
          );
        } else {
          return this.http.delete<DeleteResponse>(
            `${API_URL}${baseInvUrl}/products`,
            {
              body: {
                ids: ids.split(',').map((id) => Number(id.trim())),
              },
            },
          );
        }
      }),
    );
  }

  getVariantsByProductId(productId): Observable<ProductVariant[]> {
    return this.http.get<ProductVariant[]>(
      `${API_URL}/products/${productId}/variants`,
    );
  }

  getVariantByIdForPOS(
    payload: GetVariantByIdForPOSParams,
  ): Observable<GetVariantByIdForPOSResponse> {
    const { variantId } = payload;
    const path = `/pos/variants/${variantId}`;

    let httpPrams = new HttpParams()
      .set('locationId', payload.locationId)
      .set('variantId', payload.variantId)
      .set('offset', payload.offset)
      .set('limit', payload.limit);

    if (payload.orderBy) {
      httpPrams = httpPrams.append('orderBy', payload.orderBy);
      httpPrams = httpPrams.append('order', payload.order);
    }

    if (payload.filter) {
      httpPrams = httpPrams.append('filter', payload.filter);
    }

    const options = { params: httpPrams };

    return this.http.get<GetVariantByIdForPOSResponse>(
      `${API_URL}${path}`,
      options,
    );
  }

  getProductById(productId): Observable<any> {
    return this.featureFlagService.isEnabled(featureFlagName).pipe(
      switchMap((value) => {
        if (!value) {
          return this.http.get<Product>(`${API_URL}/products/${productId}`);
        }
        return this.http
          .get<{
            data: ProductV2;
          }>(`${API_URL}${baseInvUrl}/products/${productId}`)
          .pipe(
            switchMap((response) =>
              this.supplierService
                .getSuppliers()
                .pipe(
                  map((suppliers) =>
                    mapProductV2toV1(response.data, suppliers),
                  ),
                ),
            ),
          );
      }),
      first(),
    );
  }

  getProductVariantsByIds(
    ids = [],
  ): Observable<{ result: ProductVariant[]; total: number }> {
    return this.http.post<{ result: ProductVariant[]; total: number }>(
      `${API_URL}/products/list`,
      {
        filters: {
          id: [{ matchMode: 'in', value: ids }],
        },
        limit: ids.length,
      },
    );
  }

  getProductImages(productId): Observable<UploadFile[]> {
    return this.http.get<UploadFile[]>(
      `${API_URL}/products/${productId}/images`,
    );
  }

  publishProducts(products, appId): Observable<PublishResponse> {
    return this.http.put<PublishResponse>(
      `${API_URL}/products/publish?app=${appId}`,
      products,
    );
  }

  unPublishProducts(
    products,
    appName,
  ): Observable<{
    hasErrors: boolean;
    products: Product[];
    unpublishedProducts: number;
    productsWithOrders: number;
  }> {
    return this.http.put<{
      hasErrors: boolean;
      products: Product[];
      unpublishedProducts: number;
      productsWithOrders: number;
    }>(`${API_URL}/products/unPublish?app=${appName}`, products);
  }

  saveProductTrack(variantTracks: VariantToTrack[]): Observable<any> {
    return this.featureFlagService.isEnabled(featureFlagName).pipe(
      switchMap((value) => {
        if (!value) {
          return this.http.post<VariantToTrack[]>(
            `${API_URL}/variant-track`,
            variantTracks,
          );
        }
        return this.http.post<{ data: VariantToTrack[] }>(
          `${API_URL}${baseInvUrl}/variants/tracks`,
          mapTracksV1ToV2(variantTracks),
        );
      }),
    );
  }

  getProductReferences(productId: number): Observable<any> {
    return this.http.get<any>(`${API_URL}/products/${productId}/references`);
  }

  getBulkProductReferences(productId: number[]): Observable<any> {
    return this.featureFlagService.isEnabled(featureFlagName).pipe(
      switchMap((value) => {
        if (!value) {
          return this.http.post<any>(`${API_URL}/products/references`, {
            productIds: productId,
          });
        }
        return this.http.post<any>(
          `${API_URL}${baseInvUrl}/products/references`,
          {
            productIds: productId,
          },
        );
      }),
    );
  }

  getProductWithStock(productId: number): Observable<Product> {
    return this.http.get<any>(`${API_URL}/products/${productId}/stocks`);
  }

  async getProductImagesByIds(idList: number[]): Promise<any> {
    return this.http
      .post<any>(`${API_URL}/products/none/images/all`, { ids: idList })
      .toPromise();
  }

  async getVariantImagesByIds(idList: number[]): Promise<any> {
    return this.http
      .get<any>(`${API_URL}/variants/images?ids=${idList.toString()}`)
      .toPromise();
  }

  assignProductToCategory(productId, payload): Observable<any> {
    return this.http.put<PublishResponse>(
      `${API_URL}/products/${productId}/category`,
      payload,
    );
  }

  deleteVariantByProductId(
    productId: number,
    variantId: number,
  ): Observable<DeleteResponse> {
    return this.http.delete<DeleteResponse>(
      `${API_URL}/products/${productId}/variants/${variantId}`,
    );
  }

  checkProductReferenceInOrders(productIds: string): Observable<boolean> {
    let http = new HttpParams();
    http = http.set('isOverview', false);
    http = http.set('productIds', productIds);
    return this.http
      .get<RewaaBaseApiResponse<PendingProductResponse, undefined>>(
        `${API_URL}/omni/orders/pending-products`,
        {
          params: http,
        },
      )
      .pipe(
        map((response) => {
          const { pendingProducts } = response.data;
          for (const pp in pendingProducts) {
            if (
              pendingProducts[pp].products &&
              pendingProducts[pp].products.length
            ) {
              return true;
            }
          }
          return false;
        }),
      );
  }

  getPendingOrderSummary(
    payload: { startDate: string; endDate: string }[],
  ): Observable<any> {
    const path = `/omni/orders/pending-products/stats`;

    const params = new HttpParams().set('dateRanges', JSON.stringify(payload));

    return this.http.get(`${API_URL}${path}`, { params });
  }

  getPendingProductsByOrderId(orderId: number): Observable<any> {
    const path = `/omni/orders/pending-products?isOverview=false&orderId=${orderId}`;

    return this.http.get(`${API_URL}${path}`);
  }
}
