import { Injectable } from '@angular/core';
import * as FullStory from '@fullstory/browser';
import { datadogRum } from '@datadog/browser-rum';
import {
  KeyboardShortcutTriggeredEvent,
  OfflineCustomerEvent,
  OfflineTrackingInvoice,
  OmniInsfQtyOrUnlinkedErrorResolutionTriggeredEvent,
  PosOfflineSaleInvoiceSyncEvent,
  PosSaleInvoiceCreatedEvent,
  PosSaleInvoiceSyncEvent,
  RewaaPayConnectionData,
  RewaaPayTransactionData,
} from '../types/event-tracking.type';
import {
  PosSaleInvoiceMode,
  TrackingEventName,
} from '../constants/event-tracking.const';
import { PriceLessThanCostResolutionActions } from '../../orders-v2/enums/price-less-than-cost-resolution-actions.enum';
import { OrderStatusEnum } from '../../orders-v2/domain/enums/order-status.enum';

@Injectable()
export class EventTrackingService {
  /**
   * @deprecated
   */
  private trackOfflineInvoiceEvent(
    trackingInvoice: OfflineTrackingInvoice,
    eventType: TrackingEventName,
  ) {
    FullStory.event(eventType, {
      creationDate_date: trackingInvoice.completeDate,
      invoiceCreationTimeTakenInMS_int: trackingInvoice.timeTakenInMilliSeconds,
      invoiceNumber_str: trackingInvoice.displayInvoiceNumber,
      userId_int: trackingInvoice.userId,
      isOfflineFirst_bool: trackingInvoice.offlineFirst,
      isPlugin_bool: trackingInvoice.isPlugin,
    });
    this.trackOnDataDog(eventType, { ...trackingInvoice });
  }

  /**
   * @deprecated
   */
  public trackOfflineInvoiceCreated(
    trackingInvoice: OfflineTrackingInvoice,
  ): void {
    this.trackOfflineInvoiceEvent(
      trackingInvoice,
      TrackingEventName.OfflineInvoiceCreated,
    );
  }

  /**
   * @deprecated
   */
  public trackOfflineInvoiceSynced(
    trackingInvoices: OfflineTrackingInvoice[],
  ): void {
    trackingInvoices.forEach((trackingInvoice) => {
      this.trackOfflineInvoiceEvent(
        trackingInvoice,
        TrackingEventName.OfflineInvoiceSynced,
      );
    });
  }

  keyboardShortcutTriggered(data: KeyboardShortcutTriggeredEvent): void {
    this.trackOnDataDog(TrackingEventName.KeyboardShortcutTriggered, {
      ...data,
    });
    this.trackOnFullStory(TrackingEventName.KeyboardShortcutTriggered, {
      shortcut_str: data.shortcut,
      context_str: data.context,
      action_str: data.action,
    });
  }

  posSaleInvoiceCreated(data: PosSaleInvoiceCreatedEvent): void {
    this.trackOnDataDog(TrackingEventName.PosSaleInvoiceCreated, { ...data });
    this.trackOnFullStory(TrackingEventName.PosSaleInvoiceCreated, {
      invoiceNumber_str: data.invoiceNumber,
      mode_str: data.mode,
      pluginInstalled_bool: data.pluginInstalled,
      pluginPrint_bool: data.pluginPrint,
      browserPrint_bool: data.browserPrint,
      fallbackBrowserPrint_bool: data.fallbackBrowserPrint,
      executionTime_real: data.executionTime,
      printTime_real: data.printTime,
      zatcaEnabled_bool: data.zatcaEnabled,
      unstableInternet_bool: data.unstableInternet,
      cartId: data.cartId,
    });
  }

  posSaleInvoiceCreationFailed(mode: PosSaleInvoiceMode): void {
    this.trackOnDataDog(TrackingEventName.PosSaleInvoiceCreationFailed, {
      mode,
    });
    this.trackOnFullStory(TrackingEventName.PosSaleInvoiceCreationFailed, {
      mode_str: mode,
    });
  }

  posSaleInvoiceSynced(data: PosSaleInvoiceSyncEvent): void {
    this.trackOnDataDog(TrackingEventName.PosSaleInvoiceSynced, { ...data });
    this.trackOnFullStory(TrackingEventName.PosSaleInvoiceSynced, {
      invoiceNumber_str: data.invoiceNumber,
      mode_str: data.mode,
    });
  }

  posSaleInvoiceSyncingFailed(data: PosSaleInvoiceSyncEvent): void {
    this.trackOnDataDog(TrackingEventName.PosSaleInvoiceSyncingFailed, {
      ...data,
    });
    this.trackOnFullStory(TrackingEventName.PosSaleInvoiceSyncingFailed, {
      invoiceNumber_str: data.invoiceNumber,
      mode_str: data.mode,
    });
  }

  posOfflineSaleInvoiceSyncCompleted(
    data: PosOfflineSaleInvoiceSyncEvent,
  ): void {
    this.trackOnDataDog(TrackingEventName.PosOfflineSaleInvoiceSyncCompleted, {
      ...data,
    });
    this.trackOnFullStory(
      TrackingEventName.PosOfflineSaleInvoiceSyncCompleted,
      {
        shiftId_int: data.shiftId,
        syncedCount_int: data.syncedCount,
        totalCount_int: data.totalCount,
      },
    );
  }

  omniInsufficientQtyOrUnlinkedErrorResolutionTriggered(
    data: OmniInsfQtyOrUnlinkedErrorResolutionTriggeredEvent,
  ): void {
    this.trackOnDataDog(
      TrackingEventName.OmniInsfOrUnlinkedErrorResolutionTriggered,
      {
        ...data,
      },
    );
  }

  omniPriceLessThanCostResolutionTriggered(
    action: PriceLessThanCostResolutionActions,
  ): void {
    this.trackOnDataDog(
      TrackingEventName.OmniPriceLessThanCostResolutionTriggered,
      {
        action,
      },
    );
  }

  omniReturnInvoiceCreationTriggered(invoiceType: OrderStatusEnum): void {
    this.trackOnDataDog(TrackingEventName.OmniReturnInvoiceCreationTriggered, {
      invoiceType,
    });
  }

  logActionOnDataDog(action: TrackingEventName): void {
    this.trackOnDataDog(action, {});
  }

  rewaaPayConnectionStatus(data: RewaaPayConnectionData): void {
    this.trackOnDataDog(TrackingEventName.RewaaPayConnectionStatus, {
      ...data,
    });
    this.trackOnFullStory(TrackingEventName.RewaaPayConnectionStatus, {
      status_str: data.status,
      duration_int: data.duration || 0,
      error_str: data.error || '',
      tId_str: data.tId || '',
    });
  }

  rewaaPayTransactionStatus(data: RewaaPayTransactionData): void {
    this.trackOnDataDog(TrackingEventName.RewaaPayTransactionStatus, {
      ...data,
    });
    this.trackOnFullStory(TrackingEventName.RewaaPayTransactionStatus, {
      status_str: data.status,
      tId_str: data.tId || '',
      error_str: data.error || '',
      duration_int: data.duration || 0,
      customerReferenceNumber_str: data.customerReferenceNumber || '',
      amount_int: data.amount || 0,
    });
  }

  offlineCustomerSynced(data: OfflineCustomerEvent): void {
    this.trackOnDataDog(TrackingEventName.OfflineCustomerEvent, {
      ...data,
    });
  }

  private trackOnFullStory(
    eventName: TrackingEventName,
    properties: Record<string, unknown>,
  ): void {
    FullStory.event(eventName, properties);
  }

  private trackOnDataDog(
    eventName: TrackingEventName,
    properties: Record<string, unknown>,
  ): void {
    datadogRum.addAction(eventName, properties);
  }
}
